var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stack"},[_c('div',{staticClass:"stack-info"},[_c('div',{staticClass:"stack-info__content"},[_c('page-title',{staticClass:"stack-info__title"},[_vm._v(" "+_vm._s(_vm.$t('title.server'))+" ")]),_c('div',{staticClass:"stack-info__create"},[_c('base-button',{staticClass:"stack-info__create-btn",attrs:{"icon":_vm.isIcon,"tooltip":{
            content: _vm.text,
            autoHide: false,
            placement: 'auto',
            container: false,
            trigger: 'click hover',
          },"color":_vm.isColor,"disabled":_vm.isRequest,"to":!_vm.checkQuotas ? { name: 'CreateServer' } : {}}},[_vm._v(_vm._s(_vm.$t('newServer'))+" ")]),_c('base-button',{staticClass:"stack-info__create-btn-print",attrs:{"tooltip":{
            content: _vm.forPrint,
            autoHide: false,
            placement: 'top-end',
            container: false,
          },"disabled":_vm.isRequest,"size":"big","theme":"outlined","tabindex":"-1"},on:{"click":function($event){return _vm.print()}}},[_c('svg-icon',{staticClass:"base-btn__icon",attrs:{"size":"icons","name":_vm.icon}})],1)],1)],1)]),(_vm.isRequest)?_c('base-loader',{staticClass:"open-ticket__loader"}):(!_vm.isRequest)?_c('page-block',[(_vm.list.length === 0)?_c('base-empty',{staticClass:"cloud-info__empty",attrs:{"title":"Новый сервер"},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('base-button',[_vm._v(" "+_vm._s('Новый сервер')+" ")])]},proxy:true}],null,false,3948711897)}):_vm._e(),_c('tariffs-table-server')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }