<template>
  <div class="stack">
    <div class="stack-info">
      <div class="stack-info__content">
        <page-title class="stack-info__title">
          {{ $t('title.server') }}
        </page-title>
        <div class="stack-info__create">
          <base-button
            :icon="isIcon"
            :tooltip="{
              content: text,
              autoHide: false,
              placement: 'auto',
              container: false,
              trigger: 'click hover',
            }"
            :color="isColor"
            :disabled="isRequest"
            :to="!checkQuotas ? { name: 'CreateServer' } : {}"
            class="stack-info__create-btn"
            >{{ $t('newServer') }}
          </base-button>
          <base-button
            :tooltip="{
              content: forPrint,
              autoHide: false,
              placement: 'top-end',
              container: false,
            }"
            :disabled="isRequest"
            size="big"
            theme="outlined"
            tabindex="-1"
            class="stack-info__create-btn-print"
            @click="print()"
            ><svg-icon size="icons" :name="icon" class="base-btn__icon" />
          </base-button>
        </div>
      </div>
    </div>
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest">
      <base-empty v-if="list.length === 0" title="Новый сервер" class="cloud-info__empty">
        <template #link
          ><base-button>
            {{ 'Новый сервер' }}
          </base-button></template
        >
      </base-empty>
      <tariffs-table-server />
    </page-block>
  </div>
</template>

<script>
import TariffsTableServer from '../../Main/components/TariffsTableServer';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
import PageBlock from '@/layouts/Stack/pages/Information/components/PageBlock.vue';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'StackView',
  components: {
    TariffsTableServer,
    BaseEmpty,
    PageBlock,
  },
  mixins: [storeMixin, showErrorModal],
  props: {
    icon: {
      type: String,
      default: 'print',
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isRequest: true,
      // isRequest: false,
    };
  },
  computed: {
    isIcon() {
      return this.checkQuotas ? 'error' : '';
    },
    isColor() {
      return this.checkQuotas ? 'warn' : 'primary';
    },
    checkQuotas() {
      return this.isInstances === 0 && !this.isRequest;
    },
    isLoading() {
      return this.$store.state.moduleStack.serverRequest;
    },
    forPrint() {
      return 'Список серверов';
    },
    text() {
      if (this.isInstances === 0 && !this.isRequest) {
        return `Вы достигли квоты по количеству серверов.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else {
        return 'Создать новый сервер';
      }
    },
    isInstances() {
      if (
        this.$store.state.moduleStack.quotasServer &&
        this.$store.state.moduleStack.quotasServer.instances
      ) {
        return (
          this.$store.state.moduleStack.quotasServer.instances.limit -
          this.$store.state.moduleStack.quotasServer.instances.in_use
        );
      } else return -1;
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(async () => {
        await this.newFetch();
      })
      // .then(() => {
      //   console.log(this.$store.state.moduleStack.servers);
      // this.$store.dispatch('moduleStack/getServerTags', id).then(data => {
      //   console.log(data);
      // });
      // })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
    this.$store.dispatch('moduleStack/resetCurrentServer');
    // this.getListServersForPrint();
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
    print() {
      this.getListServersForPrint();
      // console.log(this.getListServersForPrint());
      // return alert('тут как бы печать');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Серверы",
      "plan": "Тарифный план",
      "stats": "Статистика"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "newServer": "Новый сервер",
    "quotaInstances": "Вы достигли квоты по количеству серверов, обратитесь в поддержку для изменения квоты",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    +breakpoint(ms-and-down) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1rem
    }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      align-items: flex-end;
      //flex: 1 1 auto;
      margin-top: 1.5rem;
      max-width: 300px;
      &-btn {
        margin-top: 20px;
        margin-right: 0;
        +breakpoint(ms-and-up) {
          margin-top: 40px;
        }
        &-print {
          margin-left: 1rem;
          font-size: 12px !important;
          padding: 1em 1.25rem 1em 1.5rem;
        }
      }
    +breakpoint(ms-and-down) {
      margin-top: 1rem;
    }
      &-hint {
        margin: -0.25rem 0.25rem;
        vertical-align: middle;

      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
