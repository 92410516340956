<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #server>
      <div class="tariff-table-item__server">
        <router-link
          :to="{ name: 'Server', params: { serverid: item.id } }"
          class="tariffs-table-item__link medium-text"
        >
          <!--          <text-highlight :queries="filtredSearch" class="hover">-->
          <div class="hover">
            <text-highlight :queries="filtredSearch"> {{ item.name }}</text-highlight>
            <!--          </text-highlight>-->
          </div>
        </router-link>
        <tags
          v-if="!!tags.length"
          :tags="tags"
          :filtred-tags="filtredTags"
          class="tariffs-table-item__tags"
          @click-tag="clickTag($event)"
        >
        </tags>
        <div v-if="!!item.description" class="tariffs-table-item">
          <span class="tariffs-table-item__specs note-text">
            {{ item.description }}
          </span>
        </div>
      </div>
    </template>
    <template #os>
      <div class="tariffs-table-item">
        <div class="hover">
          {{ getConfig() }}
        </div>
      </div>
    </template>
    <template #config>
      <specs-server
        :specs="{ cpu: cpu, ram: ram, local: disk, network: networkDisk }"
      ></specs-server>
    </template>
    <template #ip>
      <div class="tariffs-table-item">
        <span class="tariffs-table-item__ip">
          <div v-for="i in getIp" :key="i.name" class="hover">
            <!--            <span class="tariffs-table-item__sub">{{ i.name }}</span>-->
            <!--            <div class="hover">{{ i.name }}</div>-->
            {{ i.name }}
            <!--            <label class="medium-text tariffs-table-item__sub">{{ i.name }}</label>-->
          </div>
        </span>
      </div>
    </template>
    <template #created>
      <div class="tariffs-table-item__ip">
        {{ created(item).split(' ')[0] }} <br />
        {{ created(item).split(' ')[1] }}
      </div>
    </template>
    <template #status>
      <main-label
        :color="item.task.key !== 'нет' ? item.task.color : item.status.color"
        theme="plain"
        class="tariffs-table-item__status"
      >
        {{
          item.task && item.task.key !== 'нет'
            ? $t(`task.${item.task.key}`)
            : $t(`status.${item.status.key}`)
        }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        :placement="placement"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <template #popover>
          <server-context-menu
            :local-disk="localDisk || false"
            :tariff="item"
            class="tariff-table-item__context-menu"
          />
        </template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import ServerContextMenu from './ServerContextMenu';
import popoverFix from '@/mixins/popoverFix';
// import Tag from 'primevue/tag';
import { format } from 'date-fns';
import SpecsServer from '@/layouts/Stack/components/SpecsServer';
import Tags from '@/layouts/Stack/components/Tags';
// import TextHighlight from 'vue-text-highlight';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
export default {
  name: 'TariffsTableServerRow',
  components: {
    SpecsServer,
    // Tag,
    BaseTableRow,
    Tags,
    // TextHighlight,
    MainLabel,
    ServerContextMenu,
  },
  mixins: [popoverFix, showErrorOpenStackModal],
  props: {
    filtredTags: {
      type: Array,
      default: () => [],
    },
    filtredSearch: {
      type: String,
      default: () => '',
    },
    instanceCount: {
      type: Number,
      default: 0,
    },
    filtredType: {
      type: String,
      default: () => '',
    },
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flavorId: '',
      localDisk: false,
      listIp: [],
    };
  },
  computed: {
    elId() {
      return this.$store.state.moduleStack.current;
    },
    tags() {
      return this.item.tags;
    },
    volumeId() {
      const volumeIds = [];

      if (this.item['os-extended-volumes:volumes_attached'].length > 0) {
        this.item['os-extended-volumes:volumes_attached'].forEach(disk => {
          volumeIds.push(disk.id);
        });
      }
      return volumeIds;
    },
    placement() {
      return this.instanceCount > 2 ? 'left' : 'left-end';
    },

    getVolume() {
      const volumes = [];
      if (this.volumeId) {
        this.volumeId.forEach(disk => {
          volumes.push(this.$store.state.moduleStack.volumes.find(x => x.id === disk));
        });
      }
      return volumes;
    },
    cpu() {
      return this.item.flavor ? this.item.flavor.vcpus : null;
    },
    ram() {
      return this.item.flavor ? this.item.flavor.ram / 1024 : null;
    },
    disk() {
      return this.item.flavor ? this.item.flavor.disk : null;
    },
    id() {
      return this.item.id;
    },

    networkDisk() {
      const size = [];
      try {
        if (!!this.volumeId.length) {
          this.volumeId.forEach(disk => {
            const currentDisk = this.$store.state.moduleStack.volumes.find(x => x.id === disk);
            size.push({
              size: currentDisk.size,
              type: currentDisk.volume_type,
              id: currentDisk.id,
            });
          });
        }
      } catch {}
      return size;
    },
    getIp() {
      const item = this.item.addresses;
      const keys = Object.keys(item);
      const list = [];
      keys.forEach(key => {
        this.isPublicNetwork(key)
          ? item[key].map(x => list.push({ name: `${x.addr} public` }))
          : item[key].map(x => list.push({ name: `${x.addr} ${key}` }));
      });
      return list;
    },
    metadata() {
      if (this.volumeId.length > 0) {
        const boot = this.getVolume.find(x => x.bootable);
        return Object.keys(boot.metadata).length > 0 ? boot.metadata : boot.volume_image_metadata;
      } else return {};
    },
  },
  watch: {},
  async mounted() {
    this.getLocalDisk();
    await this.checkStatus();
  },
  methods: {
    clickTag(event) {
      this.$emit('click-tag', event);
    },
    getLocalDisk() {
      this.$store
        .dispatch('moduleStack/isLocalDisk', {
          id: this.elId,
          uuid: this.id,
        })
        .then(async data => {
          if (data && data.result === 'ok') {
            this.localDisk = data.existslocaldisk;
          }
        })
        .catch(e => console.log(e));
    },
    isPublicNetwork(network) {
      return this.$store.state.moduleStack.networks.find(x => x.name === network)
        ? this.$store.state.moduleStack.networks.find(x => x.name === network).shared
        : false;
    },
    created(i) {
      return format(i.created, 'dd.MM.yyyy HH:mm:ss');
    },
    async update(callback) {
      this.timerId = setTimeout(() => {
        this.timerCnt = 3;
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (data.status === 'ERROR' || data.status === 'ACTIVE' || data.status === 'SHUTOFF') {
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = 'data';
              // if (data.status === 'ERROR') this.showError('error');
            } else if (data.status !== 'VERIFY_RESIZE') {
              this.res = 'data';
              await this.update(callback);
            } else {
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = 'data';
              if (data.status === 'VERIFY_RESIZE') {
                this.timerCnt = 3;
                this.sendServerAction(JSON.stringify({ confirmResize: null }));
              }
              const project = this.$store.getters['moduleStack/project_id'];
              await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'servers');
              await this.$store.dispatch('moduleStack/fetchQuotasServer', project);
              await this.checkStatus(callback);
            }
          });
      }, 1000 * this.timerCnt);
    },
    async checkStatus(callback) {
      this.timerId = setTimeout(() => {
        this.timerCnt = 2;
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (data !== 'active') {
              await this.update(callback);
            } else {
              // console.log(data);
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/setServerAction', {
        id: this.item.id,
        params,
      });
    },

    getConfig() {
      try {
        let hasBootable =
          !!this.getVolume &&
          this.getVolume.length > 0 &&
          !!this.getVolume.filter(x => x.bootable) &&
          this.getVolume.filter(x => x.bootable).length > 0
            ? this.getVolume.find(x => x.bootable)
            : false;
        if (this.localDisk) {
          return this.$store.state.moduleStack.images.find(x => x.id === this.item.image.id)
            ? this.$store.state.moduleStack.images.find(x => x.id === this.item.image.id).name
            : '---';
        } else if (hasBootable && !this.localDisk && this.metadata) {
          return this.metadata ? this.metadata.image_name : null;
        } else return null;
      } catch {}
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__ip{
    position: relative;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
      }
    }
  }
  &__specs {
    flex: 1 1 auto;
    margin-top: 0.12rem;
    position: relative;
    padding: 0.5em;
    //margin: -0.5em;
    //color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);
    &:hover {
      //color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
  &__sub {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);

    //&:hover {
      &:hover::after {
        +breakpoint(ml-and-up) {
          content: '';
          display: block;
          size(2.5em, 100%);
          absolute(0px, 100%);
          background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
        }
      }
    //}

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
  &__tags {
    margin-top: 0.4rem;
    display flex
    flex-wrap: wrap;
    justify-content: flex-start;

    &-item {
      margin: 0.25rem 0 0.4rem 0.25rem

    }
  }
  &__link {
    position: relative;
    width: 100%
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);
  }
}
.hover{
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);
    z-index: 2
    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      display inline
      z-index: 3;
    }
    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        //display: block;
        display: inline;
        size(2.5em, 100%);
        absolute(0px, 100%);
        z-index: 2
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
